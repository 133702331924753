@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moirai+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}
/*Portrait phones */
@media (max-width:320px){}

/* Landscape phones and down */
@media (min-width: 321px) and (max-width: 480px) {}


@layer components{
  .blinking-cursor:after {
    content: '|';
    @apply text-white;
    animation: blink 1s step-start infinite;
    
  }
  @keyframes blink {
    50% {
      opacity: 0;  
    }
  }
}